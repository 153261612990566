<template>
  <v-container>
    <v-flex d-flex>
      <h3>Totale Ore: {{ duration }}</h3>
      <v-spacer></v-spacer>
      <v-btn text @click="openDialog()"
        ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
      >
    </v-flex>
    <h4>Attività in corso</h4>
    <v-list v-if="inProgressProject">
      <v-list-item two-line dense link>
        <v-list-item-content @click="pauseProject(inProgressProject)">
          <v-list-item-title>{{ inProgressProject.bpname }}</v-list-item-title>
          <v-list-item-subtitle>{{
            inProgressProject.description
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-btn color="error" icon @click="openStopDialog(inProgressProject)"
            ><v-icon>mdi-stop</v-icon></v-btn
          >
          <v-btn icon @click="openDialog(inProgressProject)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon @click="deleteProject(inProgressProject.c_project_id)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <p v-else>Nessuna Attività in corso</p>
    <h4>Attività in pausa</h4>
    <v-list v-if="pausedProjects.length > 0">
      <v-list-item
        v-for="project in pausedProjects"
        :key="project.c_project_id"
        two-line
        dense
        link
      >
        <v-list-item-content @click="startProject(project)">
          <v-list-item-title>{{ project.bpname }}</v-list-item-title>
          <v-list-item-subtitle>{{ project.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-btn color="error" icon @click="openStopDialog(project)"
            ><v-icon>mdi-stop</v-icon></v-btn
          >
          <v-btn icon @click="openDialog(project)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon @click="deleteProject(project.c_project_id)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <p v-else>Nessuna Attività in pausa</p>

    <br />
    <v-divider></v-divider>
    <br />
    <h4>Attività effettuate - {{ totaleOre }}</h4>
    <v-list v-if="completedProject.length > 0">
      <v-list-item
        v-for="project in completedProject"
        :key="project.c_project_id"
        two-line
        dense
        link
      >
        <v-list-item-content>
          <v-list-item-title>{{ project.bpname }}</v-list-item-title>
          <v-list-item-subtitle>{{ project.description }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ project.startdate }} - {{ project.enddate }} ({{
              project.oreImpiegate
            }})</v-list-item-subtitle
          >
        </v-list-item-content>
        <!--<v-list-item-icon>
          <v-btn color="error" icon @click="openStopDialog(project)"
            ><v-icon>mdi-stop</v-icon></v-btn
          >
          <v-btn icon @click="openDialog(project)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon @click="deleteProject(project.c_project_id)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-list-item-icon>-->
      </v-list-item>
    </v-list>
    <p v-else>Nessuna Attività Effettuata</p>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="project">
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Aggiunti Attività</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="save" :disabled="saveDisable">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <br />
          <br />
          <br />
          <br />
          <v-autocomplete
            label="Cliente"
            hide-no-data
            hide-selected
            :items="listaClienti"
            item-text="name"
            item-value="c_bpartner_id"
            v-model="project.c_bpartner_id"
            outlined
            dense
          ></v-autocomplete>
          <v-textarea
            label="Descrizione"
            outlined
            v-model="project.description"
            dense
          ></v-textarea>
          <TimePicker
            v-if="project.c_project_id > 0"
            outlined
            label="Ora Inizio"
            v-model="timeStart"
            format="24hr"
            dense
          ></TimePicker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="eliminaRapportino(c_project_id)"
            ><v-icon>mdi-delete</v-icon>Elimina</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save" :disabled="saveDisable"
            >Salva</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="stopDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="project">
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="stopDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Registra Attività</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="stopProject(project)" :disabled="saveDisable">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <br />
          <br />
          <br />
          <br />
          <v-autocomplete
            label="Cliente"
            hide-no-data
            hide-selected
            :items="listaClienti"
            item-text="name"
            item-value="c_bpartner_id"
            v-model="project.c_bpartner_id"
            outlined
            dense
          ></v-autocomplete>
          <v-flex d-flex>
            <TimePicker
              outlined
              label="Ora Inizio"
              v-model="timeStart"
              format="24hr"
              :allowedMinutes="allowedMinutes"
              dense
            ></TimePicker>
            <v-spacer></v-spacer>
            <TimePicker
              outlined
              label="Ora Fine"
              v-model="timeEnd"
              format="24hr"
              :allowedHours="allowedHours"
              :allowedMinutes="allowedEndMinutes"
              dense
            ></TimePicker>
          </v-flex>
          <v-textarea
            label="Descrizione"
            outlined
            v-model="project.description"
            dense
          ></v-textarea>
          <v-textarea label="Note" outlined v-model="project.note"></v-textarea>
          <v-select
            :items="countTypes"
            item-value="fct_counttype_id"
            item-text="name"
            label="Tipo Fatturazione"
            v-model="project.fct_counttype_id"
            outlined
            dense
          >
          </v-select>
          <v-select
            :items="notBillMotives"
            item-value="fct_notbillmot_id"
            item-text="value"
            label="Motivazione non fatturabile"
            v-model="project.fct_notbillmot_id"
            outlined
            clearable
            dense
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="stopProject(project)"
            :disabled="saveDisable"
            >Finalizza</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import ConfirmDialog from "@/components/ConfirmDialog";
import TimePicker from "@/components/TimePicker";

export default {
  mixins: [mixin],
  components: { ConfirmDialog, TimePicker },
  data() {
    return {
      dialog: false,
      stopDialog: false,
      listaClienti: [],
      projects: [],
      countTypes: [],
      notBillMotives: [],
      timeStart: "00:00",
      timeEnd: "07:00",
      project: null,
      intervalID: 0,
      completedProject: [],
    };
  },
  methods: {
    allowedMinutes: (m) => m % 15 === 0,
    allowedHours(h) {
      let startHour = parseInt(this.timeStart.split(":")[0]);
      let startMinute = parseInt(this.timeStart.split(":")[1]);
      if (startMinute >= 45) {
        return h > startHour;
      }
      return h >= startHour;
    },
    allowedEndMinutes(m) {
      let startHour = parseInt(this.timeStart.split(":")[0]);
      let endHour = parseInt(this.timeEnd.split(":")[0]);
      let startMinute = parseInt(this.timeStart.split(":")[1]);
      if (endHour > startHour) {
        return m % 15 === 0;
      }
      return m > startMinute && m % 15 === 0;
    },
    save() {
      this.dialog = false;

      let date = new Date().toISOString().substring(0, 10);
      let startDate = new Date(date + " " + this.timeStart + ":00");

      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.project.c_bpartner_id,
        description: this.project.description,
        c_project_id: this.project.c_project_id,
        startdate: startDate,
      };
      parameters.idempiereRestPath = "effecitech/createStartProject";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getInProgressProject();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    pauseProject(project) {
      let parameters = {};
      parameters.data = {
        c_project_id: project.c_project_id,
      };
      parameters.idempiereRestPath = "effecitech/pauseProject";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getInProgressProject();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    startProject(project) {
      let parameters = {};
      parameters.data = {
        c_project_id: project.c_project_id,
      };
      parameters.idempiereRestPath = "effecitech/startProject";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getInProgressProject();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    async stopProject(project) {
      if (
        !(await this.$refs.confirmDialog.open(
          "Finalizza attività",
          "Vuoi veramente finalizzare l'attività?",
          {
            color: "red",
          }
        ))
      ) {
        return;
      }
      this.stopDialog = false;
      let parameters = {};

      let date = new Date().toISOString().substring(0, 10);
      let startDate = new Date(date + " " + this.timeStart + ":00");
      let endDate = new Date(date + " " + this.timeEnd + ":00");

      parameters.data = {
        c_project_id: project.c_project_id,
        c_bpartner_id: project.c_bpartner_id,
        fct_counttype_id: project.fct_counttype_id,
        description: project.description,
        note: project.note,
        fct_notbillmot_id: project.fct_notbillmot_id,
        startdate: startDate,
        enddate: endDate,
      };
      parameters.idempiereRestPath = "effecitech/stopProject";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getInProgressProject();
          this.getCompletedProject();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    openDialog(project = {}) {
      let date = new Date(project.operationdate);
      if (isNaN(date)) {
        date = new Date(Date.now());
      }

      this.project = project;
      this.dialog = true;

      setTimeout(() => {
        this.timeStart = date.getHours().toString().padStart(2, "0") + ":";
        this.timeStart += date.getMinutes().toString().padStart(2, "0");
      }, 30);
    },
    openStopDialog(project) {
      this.project = project;
      this.stopDialog = true;

      setTimeout(() => {
        let date = new Date(project.operationdate);
        this.timeStart =
          String(date.getHours()).padStart(2, "0") +
          ":" +
          String(date.getMinutes()).padStart(2, "0");

        date = new Date(project.operationdate + project.duration);
        this.timeEnd =
          String(date.getHours()).padStart(2, "0") +
          ":" +
          String(date.getMinutes()).padStart(2, "0");
      }, 30);
    },
    async deleteProject(c_project_id) {
      let confirm = await this.$refs.confirmDialog.open(
        "Cancella rapportino",
        "Vuoi veramente cancellare il rapportino?",
        {
          color: "red",
        }
      );
      if (!confirm) return;
      this.insertDialog = false;
      this.showLoadingDialog(true, "Eliminazione rapportino...");
      let parameters = {};
      parameters.data = { c_project_id: c_project_id };
      parameters.idempiereRestPath = "effecitech/eliminaRapportino";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getInProgressProject();
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog("Errore nell'eliminazione del rapportino");
        }
      });
    },
    getInProgressProject() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getInProgressProject";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        this.projects = response.data.projects;
      });
    },
    getBPartnerList() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getBPartnerList";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.listaClienti = response.data.bpartners;
      });
    },
    getCountTypes() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAllCountType";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.countTypes = response.data.countTypes;
      });
    },
    getAllNotBillMotives() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAllNotBillMot";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.notBillMotives = response.data.notBillMotives;
      });
    },
    getCompletedProject() {
      let date = new Date().toISOString().substring(0, 10);

      let parameters = {};
      parameters.data = { startdate: date };
      parameters.idempiereRestPath = "effecitech/getListaRapportiniPerData";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.completedProject = response.data.rapportini;

        this.completedProject.forEach(elem => {
          elem.oreImpiegate = this.getOre(elem);
        });

        this.completedProject.sort((a, b) => {
          let dateA = new Date(date + " " + a.startdate);
          let dateB = new Date(date + " " + b.startdate);
          return dateA.getTime() > dateB.getTime() ? 1 : -1;
        });
      });
    },
    getOre(rapportino) {
      let startHour = parseInt(rapportino.startdate.split(":")[0]);
      let endHour = parseInt(rapportino.enddate.split(":")[0]);
      let startMinute = parseInt(rapportino.startdate.split(":")[1]);
      let endMinute = parseInt(rapportino.enddate.split(":")[1]);

      startMinute += startHour * 60;
      endMinute += endHour * 60;

      let totaleMinuti = endMinute - startMinute;

      let totaleOre = Math.floor(totaleMinuti / 60);
      totaleMinuti = Math.ceil(totaleMinuti % 60);

      if (totaleMinuti == 60) {
        totaleMinuti == 0;
        totaleOre += 1;
      }
      totaleOre = String(totaleOre);
      totaleMinuti = String(totaleMinuti);
      return totaleOre.padStart(2, "0") + ":" + totaleMinuti.padStart(2, "0");
    },
  },
  mounted() {
    this.getCountTypes();
    this.getAllNotBillMotives();
    this.getBPartnerList();
    this.getInProgressProject();
    this.getCompletedProject();

    this.intervalID = setInterval(() => {
      this.getInProgressProject();
      this.getCompletedProject();
    }, 60000);
  },
  computed: {
    saveDisable() {
      if (!this.project) return true;
      if (this.project.c_bpartner_id == null || this.project.c_bpartner_id <= 0)
        return true;
      if (this.project.description == null || this.project.description == "")
        return true;
      return false;
    },
    inProgressProject() {
      if (!this.projects) return null;
      return this.projects.find((item) => {
        return item.status == "A";
      });
    },
    pausedProjects() {
      if (!this.projects) return [];
      return this.projects.filter((item) => {
        return item.status == "P";
      });
    },
    duration() {
      if (!this.projects) return "00:00";
      let duration = 0;
      for (let project of this.projects) {
        duration += project.duration;
      }

      duration = Math.floor(duration / 1000);
      let hours = String(Math.floor(duration / 3600));
      let minutes = String(Math.floor((duration % 3600) / 60));

      let time = hours.padStart(2, "0") + ":" + minutes.padStart(2, "0");

      return time;
    },
    totaleOre() {
      let totaleMinuti = 0;
      let totaleOre = 0;
      for (let rapportino of this.completedProject) {
        let startHour = parseInt(rapportino.startdate.split(":")[0]);
        let endHour = parseInt(rapportino.enddate.split(":")[0]);
        let startMinute = parseInt(rapportino.startdate.split(":")[1]);
        let endMinute = parseInt(rapportino.enddate.split(":")[1]);

        startMinute += startHour * 60;
        endMinute += endHour * 60;

        totaleMinuti += endMinute - startMinute;
      }
      totaleOre = Math.floor(totaleMinuti / 60);
      totaleMinuti = Math.ceil(totaleMinuti % 60);

      if (totaleMinuti == 60) {
        totaleMinuti == 0;
        totaleOre += 1;
      }
      totaleOre = String(totaleOre);
      totaleMinuti = String(totaleMinuti);
      return totaleOre.padStart(2, "0") + ":" + totaleMinuti.padStart(2, "0");
    },
  },
  watch: {},
  beforeRouteLeave(to, from, next) {
    if (this.dialog) {
      this.dialog = false;
      next(false);
    } else {
      clearInterval(this.intervalID);
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.dialog) {
      this.dialog = false;
      next(false);
    } else {
      clearInterval(this.intervalID);
      next();
    }
  },
};
</script>

<style>
</style>>