<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <data-picker
          v-model="dateFrom"
          outlined
          dense
          label="Data Inizio"
          type="month"
        ></data-picker>
      </v-col>
      <v-col cols="6">
        <data-picker
          v-model="dateTo"
          outlined
          dense
          label="Data Fine"
          type="month"
        ></data-picker>
      </v-col>
    </v-row>
    <apexchart
      height="500px"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <br />
    <v-flex d-flex>
      <v-tabs v-model="tabs">
        <v-tab>Acquisti</v-tab>
        <v-tab>Vendite</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn text @click="openAddDialog"
        ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
      >
    </v-flex>
    <br />
    <v-data-table
      :headers="headers"
      :items="paySchedulesPurchase"
      dense
      v-if="tabs == 0"
    >
      <template v-slot:[`item.date`]="{ item }">
        <data-picker
          type="month"
          v-model="item.date"
          dense
          hide-details
        ></data-picker>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon color="error" @click="deletePaySchedulesPurchase(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <v-data-table :headers="headers" :items="paySchedulesSales" dense v-else>
      <template v-slot:[`item.date`]="{ item }">
        <data-picker
          type="month"
          v-model="item.date"
          dense
          hide-details
        ></data-picker>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon color="error" @click="deletePaySchedulesSales(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <br />
    <v-dialog v-model="addDialog" max-width="50%">
      <v-card>
        <v-card-title>Aggiungi</v-card-title>
        <v-card-text>
          <v-select
            outlined
            dense
            label="Tipo"
            v-model="paySchedule.isSOTrx"
            :items="payScheduleTypes"
            item-text="text"
            item-value="value"
          ></v-select>
          <data-picker
            outlined
            dense
            label="Data"
            type="month"
            v-model="paySchedule.date"
          ></data-picker>
          <v-text-field
            outlined
            dense
            label="Importo"
            type="number"
            v-model="paySchedule.amt"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="addDialog = false">Chiudi</v-btn>
          <v-btn text color="success" @click="addPaySchedule">Aggiungi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import DataPicker from "../components/DataPicker.vue";

export default {
  mixins: [mixin],
  components: {
    apexchart: VueApexCharts,
    DataPicker,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          custom: this.renderTooltip,
        },
      },
      series: [],
      payments: [],
      paySchedulesSales: [],
      paySchedulesPurchase: [],
      headers: [
        { text: "N° Documento", value: "documentno" },
        { text: "Descrizione", value: "description" },
        { text: "Data", value: "date" },
        { text: "Importo", value: "amt" },
        { text: "Elimina", value: "delete" },
      ],
      tabs: 0,
      addDialog: false,
      paySchedule: {},
      payScheduleTypes: [
        { text: "Entrata", value: true },
        { text: "Uscita", value: false },
      ],
    };
  },
  methods: {
    async getPayments() {
      let parameters = {};
      parameters.data = {
        dateFrom: moment(this.dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(this.dateTo).format("YYYY-MM-DD"),
      };
      parameters.idempiereRestPath = "effecitech/getPayments";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          let paySchedules = response.data.paySchedules;
          paySchedules.sort((a, b) => {
            return moment(a.date).unix() > moment(b.date).unix() ? 1 : -1;
          });
          this.paySchedulesSales = paySchedules.filter((item) => {
            return item.isSOTrx;
          });

          this.paySchedulesPurchase = paySchedules.filter((item) => {
            return !item.isSOTrx;
          });
          this.payments = response.data.payments;
        });
    },
    updateCategories() {
      let categories = [];
      if (!this.dateFrom || !this.dateTo) return;

      let dateFrom = moment(this.dateFrom).startOf("month");
      let dateTo = moment(this.dateTo).endOf("month");

      while (dateFrom.isBefore(dateTo)) {
        categories.push(dateFrom.format("MM-YYYY"));
        dateFrom.add(1, "M");
      }

      let chartOptions = { ...this.chartOptions };
      chartOptions.xaxis.categories = categories;
      this.chartOptions = { ...chartOptions };
    },
    async updateSeries() {
      let paySchedulesS = this.paySchedulesSales;

      let paySchedulesP = this.paySchedulesPurchase;

      let paymentsS = this.payments.filter((item) => {
        return item.isSOTrx;
      });

      let paymentsP = this.payments.filter((item) => {
        return !item.isSOTrx;
      });

      let seriesPaymentsS = { name: "Entrate", data: [] };
      let seriesPaymentsP = { name: "Uscite", data: [] };
      let seriesPaySchedulesS = { name: "Entrate Previste", data: [] };
      let seriesPaySchedulesP = { name: "Uscite Previste", data: [] };

      for (let month of this.chartOptions.xaxis.categories) {
        seriesPaymentsS.data.push({
          x: month,
          y: this.getMonthTotal(paymentsS, month),
          accountingFacts: this.getMonthFactAcct(paymentsS, month),
        });
        seriesPaymentsP.data.push({
          x: month,
          y: this.getMonthTotal(paymentsP, month),
          accountingFacts: this.getMonthFactAcct(paymentsP, month),
        });
        seriesPaySchedulesS.data.push({
          x: month,
          y: this.getMonthTotal(paySchedulesS, month),
          accountingFacts: [],
        });
        seriesPaySchedulesP.data.push({
          x: month,
          y: this.getMonthTotal(paySchedulesP, month),
          accountingFacts: [],
        });
      }

      this.series = [
        seriesPaymentsS,
        seriesPaymentsP,
        seriesPaySchedulesS,
        seriesPaySchedulesP,
      ];
    },
    getMonthTotal(series, month) {
      series = series.filter((item) => {
        return moment(item.date).format("MM-YYYY") == month;
      });
      if (series.length <= 0) return 0;
      let total = 0;
      series.forEach((item) => {
        total += item.amt;
      });
      return total.toFixed(2);
    },
    getMonthFactAcct(series, month) {
      series = series.filter((item) => {
        return moment(item.date).format("MM-YYYY") == month;
      });
      if (series.length <= 0) return [];

      let factsAcct = [];
      for (let serie of series) {
        for (let factAcct of serie.accountingFacts) {
          let element = factsAcct.find((item) => {
            return item.account_id == factAcct.account_id;
          });

          if (element != null) {
            element.amtAcctCr += factAcct.amtAcctCr;
            element.amtAcctDr += factAcct.amtAcctDr;
          } else {
            factsAcct.push({
              account_id: factAcct.account_id,
              name: factAcct.elementName,
              amtAcctCr: factAcct.amtAcctCr,
              amtAcctDr: factAcct.amtAcctDr,
            });
          }
        }
      }

      factsAcct = factsAcct.filter((item) => {
        return item.amtAcctCr > 0;
      });

      return factsAcct;
    },
    renderTooltip(params) {
      let series = params.w.config.series[params.seriesIndex];
      let data = series.data[params.dataPointIndex];

      let html = '<div class="tooltip">';
      html += '<div class="tooltip-top">' + data.x + "</div>";
      html += '<div class="tooltip-body">';
      html += series.name + " : " + data.y;

      if (data.accountingFacts.length > 0) {
        html += "<hr>";
        for (let fact of data.accountingFacts) {
          html += fact.name + " : " + fact.amtAcctCr;
        }
      }
      html += "</div></div>";
      return html;
    },
    openAddDialog() {
      this.paySchedule = {};
      this.addDialog = true;
    },
    addPaySchedule() {
      this.paySchedule.amt = parseFloat(this.paySchedule.amt);
      if (this.paySchedule.isSOTrx) {
        let c_invoicepayschedule_id = Math.min(...this.paySchedulesSales.map(x=>x.c_invoicepayschedule_id),0) - 1 ;
        this.paySchedule.c_invoicepayschedule_id = c_invoicepayschedule_id;
        
        this.paySchedulesSales.push(this.paySchedule);
      } else {
        let c_invoicepayschedule_id = Math.min(...this.paySchedulesPurchase.map(x=>x.c_invoicepayschedule_id),0) - 1 ;
        this.paySchedule.c_invoicepayschedule_id = c_invoicepayschedule_id;

        this.paySchedulesPurchase.push(this.paySchedule);
      }
      this.addDialog = false;
    },
    deletePaySchedulesSales(item) {
      this.paySchedulesSales = this.paySchedulesSales.filter((el) => {
        return el.c_invoicepayschedule_id != item.c_invoicepayschedule_id;
      });
    },
    deletePaySchedulesPurchase(item) {
      this.paySchedulesPurchase = this.paySchedulesPurchase.filter((el) => {
        return el.c_invoicepayschedule_id != item.c_invoicepayschedule_id;
      });
    },
  },
  mounted() {
    let dateFrom = moment().subtract(6, "M");
    this.dateFrom = dateFrom.format("YYYY-MM");

    let dateTo = moment().add(6, "M");
    this.dateTo = dateTo.format("YYYY-MM");

    this.getPayments();
  },
  watch: {
    dateFrom() {
      this.updateCategories();
      this.getPayments();
    },
    dateTo() {
      this.updateCategories();
      this.getPayments();
    },
    paySchedulesPurchase: {
      deep: true,
      handler: function () {
        this.updateSeries();
      },
    },
    paySchedulesSales: {
      deep: true,
      handler: function () {
        this.updateSeries();
      },
    },
    payments() {
      this.updateSeries();
    },
  },
};
</script>

<style>
.tooltip {
  min-width: 200px;
}

.tooltip-top {
  padding: 5px;
  background: lightgrey;
}

.tooltip-body {
  padding: 5px;
}
</style>