import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/login.vue'
import Home from "../views/home.vue"
import Richieste from "../views/richieste.vue"
import Asset from "../views/asset.vue"
import Activity from "../views/activity.vue"
import ImportFatturePassive from "../views/importFatturePassive.vue"
import Payments from "../views/payments.vue"
import RiepilogoFinmatica from "../views/riepilogoFinmatica.vue"

Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [{
      path: '/login',
      name: 'login',
      component: Login
    },{
      path:"/",
      name:"Home",
      component:Home
    },
    {
      path:"/richieste",
      name:"richieste",
      component:Richieste
    },
    {
      path:"/asset",
      name:"asset",
      component:Asset
    },
    {
      path:"/activity",
      name:"activity",
      component:Activity
    },
    {
      path:"/importFatturePassive",
      name:"importFatturePassive",
      component:ImportFatturePassive
    },
    {
      path:"/payments",
      name:"payments",
      component:Payments
    },
    {
      path:"/riepilogoFinmatica",
      name:"riepilogoFinmatica",
      component:RiepilogoFinmatica
    },
  ]
})