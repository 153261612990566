<template>
  <v-container fluid>
    <h4>Import Fatture</h4>
    <br />
    <v-flex d-flex>
      <v-file-input
        outlined
        label="XML Fattura Elettronica"
        dense
        accept=".xml"
        v-model="fileXml"
      ></v-file-input>
      <v-btn color="primary" @click="readXml" :disabled="!fileXml"
        >Leggi XML</v-btn
      >
    </v-flex>
    <!--<span v-if="fattura">
      <h4>Cliente : {{ anagrafica.Denominazione }}</h4>
      <h4>Data : {{ datiGeneraliDocumento.Data }}</h4>
      <h4>Partita IVA : {{ idFiscaleIVA.IdCodice }}</h4>
      <br />
      <v-data-table :headers="headersLinee" :items="linee"></v-data-table>
    </span>-->
    <span v-if="fattura">
      <h4>1 - Tipo Fattura</h4>
      <v-flex d-flex>
        <v-select
          outlined
          dense
          label="Tipo Fattura"
          :items="invoiceTypes"
          item-text="text"
          item-value="value"
          v-model="isSOTrx"
          @change="updateInvoceType"
        ></v-select>
        <v-spacer></v-spacer>
      </v-flex>
      <h4>2 - Cliente/Fornitore</h4>
      <br />
      <v-flex d-flex>
        <v-text-field
          label="Cliente/Fornitore Fattura Elettronica"
          v-model="clienteFornitore"
          outlined
          dense
          readonly
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
          label="Cliente/Fornitore Idempiere"
          v-model="c_bpartner_id"
          :items="bpartners"
          item-value="c_bpartner_id"
          item-text="name"
          outlined
          dense
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-autocomplete
          label="Indirizzo"
          v-model="c_bpartner_location_id"
          :items="bplocations"
          item-value="c_bpartner_location_id"
          item-text="name"
          outlined
          dense
        ></v-autocomplete>
      </v-flex>
      <br />
      <h4>3 - Dati Documento</h4>
      <br />
      <v-flex d-flex>
        <v-select
          label="Tipo Documento"
          v-model="c_doctype_id"
          :items="docTypes"
          item-text="name"
          item-value="c_doctype_id"
          outlined
          dense
        ></v-select>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          label="Numero Documento"
          v-model="datiGeneraliDocumento.Numero"
          readonly
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          label="Data Documento"
          v-model="datiGeneraliDocumento.Data"
          readonly
        ></v-text-field>
        <v-spacer></v-spacer>
        <DataPicker
          outlined
          dense
          label="Data Registrazione"
          v-model="dateAcct"
        ></DataPicker>
      </v-flex>
      <br />
      <h4>4 - Pagamenti</h4>
      <br />
      <v-flex d-flex>
        <v-select
          label="Regola del pagamento"
          :items="paymentRules"
          item-text="name"
          item-value="value"
          v-model="paymentRule"
          outlined
          dense
        ></v-select>
        <v-spacer></v-spacer>
        <v-select
          label="Termini di Pagamento"
          :items="paymentTerms"
          item-text="name"
          item-value="c_paymentterm_id"
          v-model="c_paymentterm_id"
          outlined
          dense
        ></v-select>
      </v-flex>
      <h4>5 - Righe</h4>
      <br />
      <v-simple-table>
        <thead>
          <tr>
            <th>Descrizione</th>
            <th>Prodotto</th>
            <th>Quantità</th>
            <th>Prezzo unitario</th>
            <th>Prezzo totale</th>
            <th>Unità di Misura</th>
            <th>Unità di Misura Idempiere</th>
            <th>Aliquota IVA</th>
            <th>Natura</th>
            <th>Aliquota Idempiere</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background: lightgray">
            <td>
              <h4>Impostazione rapida prodotti,unità di misura e aliquote</h4>
            </td>
            <td>
              <v-autocomplete
                dense
                :items="products"
                item-text="name"
                item-value="m_product_id"
                @change="setProduct"
                ref="product"
              ></v-autocomplete>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <v-select
                dense
                :items="uomList"
                item-text="name"
                item-value="c_uom_id"
                @change="setUOM"
                ref="uom"
              ></v-select>
            </td>
            <td></td>
            <td></td>
            <td>
              <v-select
                dense
                :items="taxList"
                item-text="name"
                item-value="c_tax_id"
                @change="setTax"
                ref="tax"
              ></v-select>
            </td>
          </tr>
          <v-divider></v-divider>
          <tr v-for="riga in righe" :key="riga.NumeroLinea">
            <td>{{ riga.Descrizione }}</td>
            <td>
              <v-autocomplete
                dense
                :items="products"
                item-text="name"
                item-value="m_product_id"
                v-model="riga.m_product_id"
                clearable
              ></v-autocomplete>
            </td>
            <td>{{ !riga.Quantita ? 1 : riga.Quantita}}</td>
            <td>{{ riga.PrezzoUnitario }}</td>
            <td>{{ riga.PrezzoTotale }}</td>
            <td>{{ riga.UnitaMisura }}</td>
            <td>
              <v-select
                dense
                :items="uomList"
                item-text="name"
                item-value="c_uom_id"
                v-model="riga.c_uom_id"
              ></v-select>
            </td>
            <td>{{ riga.AliquotaIVA }}</td>
            <td>{{ riga.Natura }}</td>
            <td>
              <v-select
                dense
                :items="taxList"
                item-text="name"
                item-value="c_tax_id"
                v-model="riga.c_tax_id"
              ></v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-flex d-flex>
        <v-spacer></v-spacer>
        <div>
          <b>Imponibile: {{ datiRiepilogo.ImponibileImporto }}</b>
          <br />
          <b>Imposta: {{ datiRiepilogo.Imposta }}</b>
          <br />
          <b>Totale: {{ importoTotaleDocumento }}</b>
        </div>
      </v-flex>
      <br />
      <v-flex d-flex>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="importDisable"
          @click="importFatturaElettronica"
          >Importa Fattura</v-btn
        >
      </v-flex>
    </span>
    <br />
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import DataPicker from "../components/DataPicker.vue";
const { XMLParser } = require("fast-xml-parser");

export default {
  mixins: [mixin],
  components: { ConfirmDialog, DataPicker },
  data() {
    return {
      fileXml: null,
      fattura: false,
      headersLinee: [
        { text: "N°", value: "NumeroLinea" },
        { text: "Descrizione", value: "Descrizione" },
        { text: "Qta", value: "Quantita" },
        { text: "Unita di Misura", value: "UnitaMisura" },
        { text: "Prezzo Unitario", value: "PrezzoUnitario" },
        { text: "Prezzo Totale", value: "PrezzoTotale" },
        { text: "Aliquota IVA", value: "AliquotaIVA" },
      ],
      taxList: [],
      bpartners: [],
      products: [],
      uomList: [],
      bplocations: [],
      docTypes: [],
      paymentTerms: [],
      paymentRules: [
        { name: "A Credito", value: "P" },
        { name: "Assegno", value: "S" },
        { name: "Bonifico Bancario", value: "T" },
        { name: "Carta di Credito", value: "K" },
        { name: "Cassa", value: "B" },
        { name: "Mixed", value: "M" },
      ],
      righe: [],
      c_bpartner_id: 0,
      c_bpartner_location_id: 0,
      c_doctype_id: 0,
      c_paymentterm_id: 0,
      paymentRule: "P",
      dateAcct: new Date().toISOString().substring(0, 10),
      invoiceTypes: [
        {
          text: "Fattura Acquisto",
          value: false,
        },
        {
          text: "Fattura Vendita",
          value: true,
        },
      ],
      isSOTrx: false,
    };
  },
  methods: {
    readXml() {
      this.clear();
      var reader = new FileReader();
      reader.readAsText(this.fileXml);
      reader.onload = () => {
        var options = {
          numberParseOptions: {
            leadingZeros: false,
          },
        };

        let parser = new XMLParser(options);
        let parsedXml = parser.parse(reader.result);
        let keys = Object.keys(parsedXml);
        let key = keys.find((item) => {
          return item.match("(([^:]*):)?FatturaElettronica");
        });
        this.fattura = parsedXml[key];
        this.updateInvoceType();
        this.verificaFatturaElettronica();
      };
    },
    async getTaxList() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getTaxList";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.taxList = response.data.taxList;
        });
    },
    async getPaymentTerms() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getPaymentTerms";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          console.log(response);
          this.paymentTerms = response.data.paymentTerms;
        });
    },
    async getBPartners(isSOTrx) {
      let parameters = {};
      parameters.data = {
        isVendor: !isSOTrx,
        isCustomer: isSOTrx,
      };
      parameters.idempiereRestPath = "effecitech/getBPartnerList";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.bpartners = response.data.bpartners;
        });
    },
    async getProducts() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getProductList";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.products = response.data.listaProdotti;
        });
    },
    async getUOMList() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getUOMList";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.uomList = response.data.uomList;
        });
    },
    async getInvoiceDocTypes(isSOTrx) {
      let parameters = {};
      parameters.data = {
        isSOTrx: isSOTrx,
      };
      parameters.idempiereRestPath = "effecitech/getInvoiceDocTypes";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.docTypes = response.data.docTypes;
        });
    },
    verificaFatturaElettronica() {
      this.showLoadingDialog(true, "Lettura XML in corso...");
      /*Recupero le righe*/
      let righe = [];
      console.log(this.linee);
      this.dateAcct = this.datiGeneraliDocumento.Data;
      for (let linee of this.linee) {
        /*Recupero glie eventuali codici dei prodotti*/
        let productValues = [];
        if (linee.CodiceArticolo) {
          if (Array.isArray(linee.CodiceArticolo)) {
            for (let codice of linee.CodiceArticolo) {
              productValues.push(codice.CodiceValore);
            }
          } else {
            productValues.push(linee.CodiceArticolo.CodiceValore);
          }
        }
        righe.push({
          uomSymbol: linee.UnitaMisura,
          aliquota: linee.AliquotaIVA,
          natura: linee.Natura,
          productValues: productValues,
          line: linee.NumeroLinea,
        });
      }

      let parameters = {};
      parameters.data = {
        fiscalcode: this.idFiscaleIVA.IdCodice,
        taxid: this.idFiscaleIVA.IdCodice,
        righe: righe,
      };
      parameters.idempiereRestPath = "effecitech/verificaFatturaElettronica";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        let data = response.data;
        this.c_bpartner_id = data.c_bpartner_id;
        for (let riga of data.righe) {
          let line = this.linee.find((item) => {
            return item.NumeroLinea == riga.line;
          });
          this.righe.push({
            ...line,
            c_uom_id: riga.c_uom_id,
            c_tax_id: riga.c_tax_id,
            m_product_id: riga.m_product_id,
          });
        }
        this.showLoadingDialog(false);
      });
    },
    async importFatturaElettronica() {
      if (
        !(await this.$refs.confirm.open(
          "Importa",
          "Vuoi importare la fattura?",
          { color: "primary" }
        ))
      )
        return;
      this.showLoadingDialog(true, "Import in corso...");
      /*Elaboro le righe */
      let righe = [];
      for (let riga of this.righe) {
        if (riga.Quantita == null) {
          riga.Quantita = 0;
        }
        righe.push({
          description: riga.Descrizione,
          m_product_id: riga.m_product_id,
          c_uom_id: riga.c_uom_id,
          c_tax_id: riga.c_tax_id,
          qtyEntered: !riga.Quantita ? 1 : riga.Quantita,
          priceEntered: riga.PrezzoUnitario,
        });
      }

      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_bpartner_id,
        c_bpartner_location_id: this.c_bpartner_location_id,
        c_doctypetarget_id: this.c_doctype_id,
        c_paymentterm_id: this.c_paymentterm_id,
        paymentRule: this.paymentRule,
        dateInvoiced: this.datiGeneraliDocumento.Data,
        dateAcct: this.dateAcct,
        grandTotal: this.importoTotaleDocumento,
        documentno: this.datiGeneraliDocumento.Numero,
        righe: righe,
        isSOTrx: this.isSOTrx,
      };
      parameters.idempiereRestPath = "effecitech/importFatturaElettronica";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          let message =
            "Fattura n° " + response.data.documentno + " creata corretamente";
          if (response.data.message) {
            message += " - " + response.data.message;
          }
          this.showInfoDialog(message);
          this.clear();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    clear() {
      this.c_bpartner_id = 0;
      this.c_bpartner_location_id = 0;
      this.c_doctype_id = 0;
      this.c_paymentterm_id = 0;
      this.paymentRule = "P";
      this.righe = [];
      this.fattura = false;
    },
    setProduct(m_product_id) {
      if (!m_product_id) return;
      for (let riga of this.righe) {
        if (riga.m_product_id <= 0) {
          riga.m_product_id = m_product_id;
        }
      }
      this.$refs.product.reset();
    },
    setUOM(c_uom_id) {
      if (!c_uom_id) return;
      for (let riga of this.righe) {
        if (riga.c_uom_id <= 0) {
          riga.c_uom_id = c_uom_id;
        }
      }
    },
    setTax(c_tax_id) {
      if (!c_tax_id) return;
      for (let riga of this.righe) {
        if (riga.c_tax_id <= 0) {
          riga.c_tax_id = c_tax_id;
        }
      }
    },
    updateInvoceType() {
      this.c_bpartner_id = 0;
      this.c_doctype_id = 0;

      this.getBPartners(this.isSOTrx);
      this.getInvoiceDocTypes(this.isSOTrx);
    },
  },
  async mounted() {
    this.showLoadingDialog(true, "Caricamento ...");
    await this.getTaxList();
    await this.getProducts();
    await this.getUOMList();
    await this.getPaymentTerms();
    this.showLoadingDialog(false);
  },
  computed: {
    header() {
      return this.fattura.FatturaElettronicaHeader || {};
    },
    body() {
      return this.fattura.FatturaElettronicaBody || {};
    },
    datiCedente: {
      cache: false,
      get() {
        return this.header.CedentePrestatore || {};
      },
    },
    datiAnagraficiCedente: {
      cache: false,
      get() {
        return this.datiCedente.DatiAnagrafici || {};
      },
    },
    anagraficaCedente: {
      cache: false,
      get() {
        return this.datiAnagraficiCedente.Anagrafica || {};
      },
    },
    idFiscaleIVA: {
      cache: false,
      get() {
        return this.datiAnagraficiCedente.IdFiscaleIVA || {};
      },
    },
    datiBeniServizi: {
      cache: false,
      get() {
        return this.body.DatiBeniServizi || {};
      },
    },
    datiCessionario: {
      cache: false,
      get() {
        return this.header.CessionarioCommittente || {};
      },
    },
    datiAnagraficiCessionario: {
      cache: false,
      get() {
        return this.datiCessionario.DatiAnagrafici || {};
      },
    },
    anagraficaCessionario: {
      cache: false,
      get() {
        return this.datiAnagraficiCessionario.Anagrafica || {};
      },
    },
    linee: {
      cache: false,
      get() {
        let linee = this.datiBeniServizi.DettaglioLinee;
        if (linee == null) linee = [];
        if (!Array.isArray(linee)) {
          linee = [linee];
        }
        return linee;
      },
    },
    datiGenerali: {
      cache: false,
      get() {
        return this.body.DatiGenerali || {};
      },
    },
    datiGeneraliDocumento: {
      cache: false,
      get() {
        return this.datiGenerali.DatiGeneraliDocumento || {};
      },
    },
    datiRiepilogo: {
      cache: false,
      get() {
        return this.datiBeniServizi.DatiRiepilogo || {};
      },
    },
    importoTotaleDocumento: {
      cache: false,
      get() {
        if (this.datiGeneraliDocumento.ImportoTotaleDocumento)
          return this.datiGeneraliDocumento.ImportoTotaleDocumento;
        return (
          this.datiRiepilogo.ImponibileImporto + this.datiRiepilogo.Imposta
        );
      },
    },
    importDisable() {
      if (!this.c_bpartner_id) return true;
      if (!this.c_doctype_id) return true;
      if (!this.c_paymentterm_id) return true;
      if (!this.paymentRule) return true;
      for (let riga of this.righe) {
        if (!riga.c_uom_id) return true;
        if (!riga.c_tax_id) return true;
      }
      return false;
    },
    clienteFornitore: {
      cache: false,
      get() {
        if(this.isSOTrx) return this.anagraficaCessionario.Denominazione || "";
        return this.anagraficaCedente.Denominazione || "";
      },
    },
  },
  watch: {
    c_bpartner_id() {
      if (!this.c_bpartner_id) return [];
      let parameters = {};
      parameters.data = { c_bpartner_id: this.c_bpartner_id };
      parameters.idempiereRestPath = "effecitech/getBPLocations";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.bplocations = response.data.locations;
        if (this.bplocations[0]) {
          this.c_bpartner_location_id =
            this.bplocations[0].c_bpartner_location_id;
        }
      });
    },
  },
};
</script>

<style>
</style>