import Vue from 'vue'
import App from './App.vue'
import {
  store
} from './store/store'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import {
  Conf
} from './conf/defaults'
import VueSignaturePad from 'vue-signature-pad'

export const serverBus = new Vue()

Vue.config.productionTip = false

var options = {
  persist: true
}
Vue.use(VueSession, options)

Vue.use(VueSignaturePad)

Vue.prototype.$windowTitle = Conf.windowTitle

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
