<template>
  <v-container>
    <v-autocomplete
      label="Cliente"
      hide-no-data
      hide-selected
      :items="bpartners"
      item-text="name"
      item-value="c_bpartner_id"
      v-model="c_bpartner_id"
      outlined
      dense
    ></v-autocomplete>
    <v-row>
      <v-col>
        <DatePicker
          label="Data Inizio"
          v-model="dateStart"
          outlined
          dense
        ></DatePicker>
      </v-col>
      <v-col>
        <DatePicker
          label="Data Fine"
          v-model="dateEnd"
          outlined
          dense
        ></DatePicker>
      </v-col>
    </v-row>
    <v-flex d-flex>
      <v-spacer></v-spacer>
      <v-btn @click="exportExcel" :disabled="exportDisable">Esporta</v-btn>
    </v-flex>
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import DatePicker from "@/components/DataPicker";
import { saveAs } from "file-saver";

const moment = require("moment");
const ExcelJS = require("exceljs");

export default {
  mixins: [mixin],
  components: { DatePicker },
  data() {
    return {
      dateStart: new Date().toISOString().substring(0, 10),
      dateEnd: new Date().toISOString().substring(0, 10),
      bpartners: [],
      c_bpartner_id: 0//1000096,
    };
  },
  methods: {
    getBPartnerList() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getBPartnerList";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.bpartners = response.data.bpartners;
      });
    },
    async getListaRapportini(date) {
      let parameters = {};
      parameters.data = { startdate: date };
      parameters.idempiereRestPath = "effecitech/getListaRapportiniPerData";
      let response = await this.$store.dispatch(
        "callIdempiereRest",
        parameters
      );
      let rapportini = response.data.rapportini;
      return rapportini;
    },
    async getProjects() {
      let dateStart = moment(this.dateStart);
      let dateEnd = moment(this.dateEnd);

      let projects = [];

      for (
        let date = moment(dateStart);
        date.isSameOrBefore(dateEnd);
        date.add(1, "days")
      ) {
        let rapportini = await this.getListaRapportini(
          date.format("YYYY-MM-DD")
        );
        for (let rapportino of rapportini) {
          if (rapportino.c_bpartner_id != this.c_bpartner_id) {
            continue;
          }

          let match = rapportino.description.match(/#\d+/);
          if (match != null) {
            let code = match[0];

            // Cerco il progetto
            let project = projects.find((item) => {
              return item.code == code;
            });

            // Se non trovo il progetto lo inserisco
            if (project == null) {
              project = { code: code, days: [] };
              projects.push(project);
            }

            // Cerco la data
            let day = project.days.find((item) => {
              return item.date == date.format("YYYY-MM-DD");
            });

            // Se non trovo la data la inserisco
            if (day == null) {
              day = { date: date.format("YYYY-MM-DD"), hours: "00:00" };
              project.days.push(day);
            }

            day.hours = this.addTime(day, rapportino);
          }
        }
      }
      return projects;
    },
    addTime(day, rapportino) {
      let startDate = new Date(rapportino.datedoc + " " + rapportino.startdate);
      let endDate = new Date(rapportino.datedoc + " " + rapportino.enddate);

      let time = new Date(endDate.getTime() - startDate.getTime());

      let hours = parseInt(day.hours.split(":")[0]) + time.getUTCHours();
      let minutes = parseInt(day.hours.split(":")[1]) + time.getUTCMinutes();

      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;

      hours = String(hours).padStart(2, "0");
      minutes = String(minutes).padStart(2, "0");

      return hours + ":" + minutes;
    },
    async exportExcel() {
      let projects = await this.getProjects();

      let data = await fetch("reportFinmatica.xlsx");
      let buffer = await Buffer.from(await data.arrayBuffer());

      let workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      var worksheet = workbook.getWorksheet(1);

      worksheet.getCell("AH4").value = this.$session.get("name");

      for (let i = 0; i < projects.length; i++) {
        let project = projects[i];
        let row = i + 11;

        worksheet.getCell("A" + row).value = project.code;

        for (let day of project.days) {
          let date = new Date(day.date);
          let column = date.getDate() + 9;

          let hours = parseInt(day.hours.split(":")[0]);
          let minutes = parseInt(day.hours.split(":")[1]);
          hours += minutes / 60;

          worksheet.getCell(row, column).value = hours;
        }
      }

      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }),
          "export.xlsx"
        );
      });
    },
  },
  mounted() {
    this.getBPartnerList();
    this.dateStart = moment(new Date()).startOf("month").format("YYYY-MM-DD");
    this.dateEnd = moment(new Date()).endOf("month").format("YYYY-MM-DD");
  },
  computed:{
    exportDisable(){
      if(this.c_bpartner_id <= 0) return true;
      if(!this.dateStart) return true;
      if(!this.dateEnd) return true;
      return false;
    }
  }
};
</script>

<style>
</style>