<template>
  <v-container>
    <v-flex d-flex>
      <v-btn x-large icon @click="prevDate">
        <v-icon x-large>mdi-chevron-left</v-icon>
      </v-btn>
      <DatePicker
        label="Data"
        outlined
        v-model="data"
        :events="giorniConRapportini"
        eventColor="success"
      ></DatePicker>
      <v-btn x-large icon @click="nextDate">
        <v-icon x-large>mdi-chevron-right</v-icon>
      </v-btn>
    </v-flex>
    <v-flex d-flex>
      <h4>Attività registrate - Totale Ore {{ totaleOre }}</h4>
      <v-spacer></v-spacer>
      <v-btn text @click.stop="openInsertDialog()"
        ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
      >
    </v-flex>
    <v-list>
      <v-list-item
        link
        v-for="rapportino in listaRapportini"
        :key="rapportino.c_project_id"
        @click="openInsertDialog(rapportino)"
        three-line
        dense
      >
        <v-list-item-content>
          <v-list-item-title>{{ rapportino.bpname }}</v-list-item-title>
          <v-list-item-subtitle>{{
            rapportino.description
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ rapportino.startdate }} - {{ rapportino.enddate }} ({{
              rapportino.oreImpiegate
            }})</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-icon>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                @click="eliminaRapportino(rapportino.c_project_id)"
              >
                <v-list-item-title>Elimina</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-dialog
      v-model="insertDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="insertDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Aggiunti Attività</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="save" :disabled="saveDisable">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <br />
          <br />
          <br />
          <br />
          <v-text-field
            v-if="r_request_id > 0"
            label="Richiesta"
            v-model="summary"
            outlined
            readonly
            dense
          ></v-text-field>
          <v-autocomplete
            label="Cliente"
            hide-no-data
            hide-selected
            :items="listaClienti"
            item-text="name"
            item-value="c_bpartner_id"
            :search-input.sync="ricercaCliente"
            v-model="c_bpartner_id"
            outlined
            dense
          ></v-autocomplete>
          <v-flex d-flex>
            <TimePicker
              outlined
              label="Ora Inizio"
              v-model="timeStart"
              format="24hr"
              :allowedMinutes="allowedMinutes"
              dense
            ></TimePicker>
            <v-spacer></v-spacer>
            <TimePicker
              outlined
              label="Ora Fine"
              v-model="timeEnd"
              format="24hr"
              :allowedHours="allowedHours"
              :allowedMinutes="allowedEndMinutes"
              dense
            ></TimePicker>
          </v-flex>
          <v-textarea
            label="Descrizione"
            outlined
            v-model="description"
            dense
          ></v-textarea>
          <v-textarea label="Note" outlined v-model="note"></v-textarea>
          <v-select
            :items="countTypes"
            item-value="fct_counttype_id"
            item-text="name"
            label="Tipo Fatturazione"
            v-model="fct_counttype_id"
            outlined
            dense
          >
          </v-select>
          <v-select
            :items="notBillMotives"
            item-value="fct_notbillmot_id"
            item-text="value"
            label="Motivazione non fatturabile"
            v-model="fct_notbillmot_id"
            outlined
            clearable
            dense
          >
          </v-select>
          <v-select
            label="Richieste"
            v-model="selectedRequests"
            :items="filteredRequest"
            item-text="summary"
            item-value="r_request_id"
            return-object
            multiple
            chips
            outlined
            dense
          ></v-select>
          <v-checkbox v-model="sendEMail" label="Spedisci EMail"></v-checkbox>
          <span v-if="sendEMail">
            <v-alert
              color="success"
              type="success"
              dark
              text
              dense
              v-if="getBPEmail(this.c_bpartner_id) != null"
            >
              Email Cliente: {{ getBPEmail(this.c_bpartner_id) }}
            </v-alert>
            <v-alert type="error" text v-else dense>
              Email non impostata!
            </v-alert>
          </span>
          <h4>Firma cliente</h4>
          <VueSignaturePad
            style="border: 1px solid #6c757d"
            id="signature"
            width="100%"
            height="150px"
            ref="signature"
          />
          <v-btn @click="clearSignature" text>Cancella firma</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="eliminaRapportino(c_project_id)"
            ><v-icon>mdi-delete</v-icon>Elimina</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save" :disabled="saveDisable"
            >Salva</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script>
import DatePicker from "@/components/DataPicker";
import TimePicker from "@/components/TimePicker";
import ConfirmDialog from "@/components/ConfirmDialog";
import mixin from "../mixin/MixingCommonComp";

export default {
  name: "Home",
  components: { DatePicker, TimePicker, ConfirmDialog },
  mixins: [mixin],
  data() {
    return {
      data: new Date().toISOString().substring(0, 10),
      insertDialog: false,
      ricercaCliente: "",
      listaClienti: [],
      listaRapportini: [],
      c_bpartner_id: 0,
      timeStart: "00:00",
      timeEnd: "00:00",
      description: "",
      note: "",
      fct_notbillmot_id: 0,
      sendEMail: false,
      menu: false,
      countTypes: [],
      fct_counttype_id: 0,
      c_project_id: 0,
      giorniConRapportini: [],
      notBillMotives: [],
      r_request_id: 0,
      summary: "",
      selectedRequests: [],
    };
  },
  methods: {
    allowedMinutes: (m) => m % 15 === 0,
    allowedHours(h) {
      let startHour = parseInt(this.timeStart.split(":")[0]);
      let startMinute = parseInt(this.timeStart.split(":")[1]);
      if (startMinute >= 45) {
        return h > startHour;
      }
      return h >= startHour;
    },
    allowedEndMinutes(m) {
      let startHour = parseInt(this.timeStart.split(":")[0]);
      let endHour = parseInt(this.timeEnd.split(":")[0]);
      let startMinute = parseInt(this.timeStart.split(":")[1]);
      if (endHour > startHour) {
        return m % 15 === 0;
      }
      return m > startMinute && m % 15 === 0;
    },
    openInsertDialog(rapportino, r_request_id = 0) {
      this.insertDialog = true;
      var that = this;
      setTimeout(() => {
        that.clearSignature();
        if (rapportino != null) {
          that.ricercaCliente = rapportino.bpname;
          that.c_bpartner_id = rapportino.c_bpartner_id;
          that.timeStart = rapportino.startdate;
          that.timeEnd = rapportino.enddate;
          that.description = rapportino.description;
          that.note = rapportino.note;
          that.fct_counttype_id = rapportino.fct_counttype_id;
          that.c_project_id = rapportino.c_project_id;
          that.fct_notbillmot_id = rapportino.fct_notbillmot_id;
          that.sendEMail = rapportino.sendEMail;
          that.selectedRequests = rapportino.requests;
          if (rapportino.signature != null && rapportino.signature != "") {
            that.$refs.signature.resizeCanvas();
            that.$refs.signature.fromDataURL(
              "data:image/png;base64," + rapportino.signature
            );
          }
        } else {
          let now = new Date();
          that.ricercaCliente = "";
          that.c_bpartner_id = 0;
          that.timeStart =
            now.getHours().toString().padStart(2, "0") +
            ":" +
            ((Math.round(now.getMinutes() / 15) * 15) % 60)
              .toString()
              .padStart(2, "0");
          that.timeEnd =
            now.getHours().toString().padStart(2, "0") +
            ":" +
            ((Math.round(now.getMinutes() / 15) * 15) % 60)
              .toString()
              .padStart(2, "0");
          that.description = "";
          that.note = "";
          that.fct_counttype_id = 0;
          that.c_project_id = 0;
          that.fct_notbillmot_id = 0;
          that.sendEMail = false;
          that.selectedRequests = [];
        }
        if (r_request_id > 0) {
          let request = this.requests.find((item) => {
            return item.r_request_id == r_request_id;
          });
          this.r_request_id = r_request_id;
          this.summary = request.summary;
          if (request.c_bpartner_id > 0) {
            this.c_bpartner_id = request.c_bpartner_id;
            this.ricercaCliente = request.bpname;
          }
        } else {
          this.r_request_id = 0;
        }
      }, 10);
    },
    async save() {
      if (this.sendEMail) {
        if (
          !(await this.$refs.confirmDialog.open(
            "Attenzione",
            "Verrà inviata un' EMail al cliente, continuare?",
            { color: "error" }
          ))
        ) {
          return;
        }
      }
      this.showLoadingDialog(true, "Creazione rapportino...");
      this.insertDialog = false;
      let startDate = new Date(this.data + " " + this.timeStart + ":00");
      let endDate = new Date(this.data + " " + this.timeEnd + ":00");
      let parameters = {};
      let signature = "";
      if (!this.$refs.signature.isEmpty()) {
        signature = this.$refs.signature.saveSignature().data.split(",")[1];
      }
      parameters.data = {
        c_project_id: this.c_project_id,
        c_bpartner_id: this.c_bpartner_id,
        fct_counttype_id: this.fct_counttype_id,
        description: this.description,
        note: this.note,
        startdate: startDate,
        enddate: endDate,
        fct_notbillmot_id: this.fct_notbillmot_id,
        signature: signature,
        sendEMail: this.sendEMail,
        r_request_id: this.r_request_id,
        requests: this.selectedRequests,
      };
      parameters.idempiereRestPath = "effecitech/salvaRapportino";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        this.showLoadingDialog(false);
        if (response.data.result == "OK") {
          if (this.c_project_id <= 0) {
            this.listaRapportini.push({
              c_project_id: response.data.c_project_id,
              c_bpartner_id: this.c_bpartner_id,
              fct_counttype_id: this.fct_counttype_id,
              fct_notbillmot_id: this.fct_notbillmot_id,
              description: this.description,
              note: this.note,
              startdate: this.timeStart,
              enddate: this.timeEnd,
              bpname: this.ricercaCliente,
              datedoc: this.data,
              requests: this.selectedRequests,
            });
            this.giorniConRapportini.push(this.data);
          }
          //console.log("lista giorno");
          this.getListaGiorniConRapportini();
          this.getListaRapportini(this.data);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    getCountTypes() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAllCountType";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.countTypes = response.data.countTypes;
      });
    },
    getListaRapportini(date) {
      let that = this;
      this.showLoadingDialog(true, "Caricamento rapportini...");
      let parameters = {};
      parameters.data = { startdate: date };
      parameters.idempiereRestPath = "effecitech/getListaRapportiniPerData";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        this.listaRapportini = response.data.rapportini;

        this.listaRapportini.forEach(function (elem) {
          elem.oreImpiegate = that.getOre(elem);
        });
        this.listaRapportini.sort((a, b) => {
          let dateA = new Date(this.data + " " + a.startdate);
          let dateB = new Date(this.data + " " + b.startdate);
          return dateA.getTime() > dateB.getTime() ? 1 : -1;
        });

        this.listaRapportini.sort(function (a, b) {
          return b.startdate - a.startdate;
        });

        //this.listaRapportini = []

        console.log(this.listaRapportini);

        this.showLoadingDialog(false);
      });
    },
    async eliminaRapportino(c_project_id) {
      let confirm = await this.$refs.confirmDialog.open(
        "Cancella rapportino",
        "Vuoi veramente cancellare il rapportino?",
        {
          color: "red",
        }
      );
      if (!confirm) return;
      this.insertDialog = false;
      this.showLoadingDialog(true, "Eliminazione rapportino...");
      let parameters = {};
      parameters.data = { c_project_id: c_project_id };
      parameters.idempiereRestPath = "effecitech/eliminaRapportino";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        if (response.data.result == "OK") {
          this.listaRapportini = this.listaRapportini.filter((item) => {
            return item.c_project_id != c_project_id;
          });
          this.getListaGiorniConRapportini();
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog("Errore nell'eliminazione del rapportino");
        }
      });
    },
    getListaGiorniConRapportini() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getListaGiorniConRapportini";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.giorniConRapportini = response.data.giorniConRapportini;
      });
    },
    getAllNotBillMotives() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAllNotBillMot";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.notBillMotives = response.data.notBillMotives;
      });
    },
    clearSignature() {
      this.$refs.signature.clearSignature();
    },
    getRequests() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getRequests";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        let requests = response.data.requests;
        requests.sort((a, b) => {
          let datea = new Date(a.startDate);
          let dateb = new Date(b.startDate);
          return dateb.getTime() > datea.getTime() ? 1 : -1;
        });
        this.requests = requests;
      });
    },
    getBPEmail(c_bpartner_id) {
      if (c_bpartner_id <= 0) return null;
      let bpartner = this.listaClienti.find((item) => {
        return item.c_bpartner_id == c_bpartner_id;
      });
      if (bpartner == null) return null;
      return bpartner.email != "" ? bpartner.email : null;
    },
    getOre(rapportino) {
      let startHour = parseInt(rapportino.startdate.split(":")[0]);
      let endHour = parseInt(rapportino.enddate.split(":")[0]);
      let startMinute = parseInt(rapportino.startdate.split(":")[1]);
      let endMinute = parseInt(rapportino.enddate.split(":")[1]);

      startMinute += startHour * 60;
      endMinute += endHour * 60;

      let totaleMinuti = endMinute - startMinute;

      let totaleOre = Math.floor(totaleMinuti / 60);
      totaleMinuti = Math.ceil(totaleMinuti % 60);

      if (totaleMinuti == 60) {
        totaleMinuti == 0;
        totaleOre += 1;
      }
      totaleOre = String(totaleOre);
      totaleMinuti = String(totaleMinuti);
      return totaleOre.padStart(2, "0") + ":" + totaleMinuti.padStart(2, "0");
    },
    nextDate(){
      let date = new Date(this.data)
      date.setDate(date.getDate()+1)
      this.data = date.toISOString().substring(0,10)
    },
    prevDate(){
      let date = new Date(this.data)
      date.setDate(date.getDate()-1)
      this.data = date.toISOString().substring(0,10)
    }
  },
  mounted() {
    if (this.$route.query.r_request_id != null) {
      let query = Object.assign({}, this.$route.query);
      let r_request_id = query.r_request_id;
      delete query.r_request_id;
      this.$router.replace({ query });
      this.openInsertDialog(null, r_request_id);
    }
    this.getRequests();
    this.getCountTypes();
    this.getListaGiorniConRapportini();
    this.getAllNotBillMotives();
    this.getListaRapportini(this.data);
  },

  watch: {
    ricercaCliente: function () {
      if (
        this.ricercaCliente == null ||
        this.ricercaCliente == "" ||
        this.ricercaCliente.length < 3
      )
        return;
      let parameters = {};
      parameters.data = { name: this.ricercaCliente };
      parameters.idempiereRestPath = "effecitech/cercaCliente";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response)
        if (response.data.result == "OK") {
          this.listaClienti = response.data.listaClienti;
        }
      });
    },
    //Cambio della data --> ricerco gli eventi per il giorno
    data: function () {
      this.getListaRapportini(this.data);
    },
  },
  computed: {
    saveDisable() {
      if (this.c_bpartner_id == null || this.c_bpartner_id <= 0) return true;
      if (this.fct_counttype_id == null || this.fct_counttype_id <= 0)
        return true;
      if (this.timeStart == null || this.timeStart == "") return true;
      if (this.timeEnd == null || this.timeEnd == "") return true;
      if (this.description == null || this.description == "") return true;
      let dateStart = new Date("1970-01-01 " + this.timeStart + ":00");
      let dateEnd = new Date("1970-01-01 " + this.timeEnd + ":00");
      if (dateStart.getTime() >= dateEnd.getTime()) return true;
      return false;
    },
    totaleOre() {
      let totaleMinuti = 0;
      let totaleOre = 0;
      for (let rapportino of this.listaRapportini) {
        let startHour = parseInt(rapportino.startdate.split(":")[0]);
        let endHour = parseInt(rapportino.enddate.split(":")[0]);
        let startMinute = parseInt(rapportino.startdate.split(":")[1]);
        let endMinute = parseInt(rapportino.enddate.split(":")[1]);

        startMinute += startHour * 60;
        endMinute += endHour * 60;

        totaleMinuti += endMinute - startMinute;
      }
      totaleOre = Math.floor(totaleMinuti / 60);
      totaleMinuti = Math.ceil(totaleMinuti % 60);

      if (totaleMinuti == 60) {
        totaleMinuti == 0;
        totaleOre += 1;
      }
      totaleOre = String(totaleOre);
      totaleMinuti = String(totaleMinuti);
      return totaleOre.padStart(2, "0") + ":" + totaleMinuti.padStart(2, "0");
    },
    requests: {
      get() {
        return this.$store.state.requests;
      },
      set(value) {
        this.$store.commit("setRequests", value);
      },
    },
    filteredRequest: function () {
      if (this.c_bpartner_id <= 0) return this.requests;
      return this.requests.filter((item) => {
        return item.c_bpartner_id == this.c_bpartner_id;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.insertDialog) {
      this.insertDialog = false;
      next(false);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.insertDialog) {
      this.insertDialog = false;
      next(false);
    } else {
      next();
    }
  },
};
</script>
