<template>
  <v-app>
    <v-app-bar app color="effecitech">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <b>BENVENUTO {{ this.$session.get("name") }}</b>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="logout" v-if="loginEffettuato()">
        {{ !$vuetify.breakpoint.smAndDown ? "Logout" : "" }}
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav>
        <v-list-item @click="home">
          <v-list-item-title>Registrazione Ore</v-list-item-title>
        </v-list-item>
        <v-list-item @click="richieste">
          <v-list-item-title>Richieste</v-list-item-title>
        </v-list-item>
        <v-list-item @click="activity">
          <v-list-item-title>Attività</v-list-item-title>
        </v-list-item>
        <v-list-item @click="asset">
          <v-list-item-title>Dispositivi</v-list-item-title>
        </v-list-item>
        <v-list-item @click="importFatture">
          <v-list-item-title>Import Fatture Passive</v-list-item-title>
        </v-list-item>
        <v-list-item @click="riepilogoFinmatica">
          <v-list-item-title>Riepilogo Finmatica</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-footer bottom absolute caption color="white" style="font-size: 10px">
        Build:<strong>{{ buildno }}</strong>
      </v-footer>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <DialogOperationRunning
          name="dialogOperationRunning"
          v-model="progressMessageEnable"
          :dialogTitle="operationRunningTitle"
          :dialogMessage="progressMessage"
          :loading="progressLoading"
        />
        <DialogError
          v-model="errorMessageEnable"
          name="dialogError"
          :dialogTitle="errorDialogTitle"
          :dialogMessage="errorMessage"
        />
        <DialogInfo
          v-model="infoMessageEnable"
          name="dialogError"
          dialogTitle="Info"
          :dialogMessage="infoMessage"
        />
      </v-container>
      <v-footer app bottom fixed color="white">
        <v-flex d-flex justify-center>
          <v-img :src="image" max-width="93px" max-height="30px"></v-img>
        </v-flex>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import MixinCommonComp from "./mixin/MixingCommonComp";

export default {
  mixins: [MixinCommonComp],
  name: "App",
  data: () => ({
    drawer: false,
    image: require("@/assets/logo.png"),
  }),
  methods: {
    logout() {
      this.$session.clear();
      this.$session.destroy();
      this.$router.push({ path: "/Login/" });
    },
    home() {
      this.$router.push({ path: "/" });
    },
    richieste() {
      this.$router.push({ path: "/richieste" });
    },
    asset() {
      this.$router.push({ path: "/asset" });
    },
    importFatture() {
      this.$router.push({ path: "/importFatturePassive" });
    },
    activity() {
      this.$router.push({ path: "/activity" });
    },
    riepilogoFinmatica(){
      this.$router.push({ path: "/riepilogoFinmatica" });
    }
  },
};
</script>
