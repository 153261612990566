<template>
  <v-container>
    <v-flex d-flex>
      <h2>Richieste aperte</h2>
      <v-spacer></v-spacer>
      <v-btn text @click="openNewRequestDialog"
        ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
      >
    </v-flex>
    <br>
    <span v-for="(group,index) in requestGrouped" :key="index">
      <h4>{{index}}</h4>
    <v-list dense>
      <v-list-item
        v-for="request in group"
        :key="request.r_request_id"
        @click="openRequestDialog(request)"
      >
        <v-list-item-title
          >{{ request.summary }}</v-list-item-title
        >
        <v-list-item-action>
          <v-tooltip bottom v-if="daysFromNow(request.endTime) <= 7">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="getDeadlineColor(daysFromNow(request.endTime))"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert
              </v-icon>
            </template>
            <span
              >{{ daysFromNow(request.endTime) }} giorni alla scadenza!</span
            >
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <br>
    </span>
    <v-dialog
      v-model="requestDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="requestDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Richiesta</h4>
        </v-app-bar>
        <br />
        <br />
        <br />
        <br />
        <v-card-text>
          <v-text-field
            outlined
            v-model="request.bpname"
            label="Cliente"
            readonly
            dense
          ></v-text-field>
          <v-textarea
            outlined
            v-model="request.summary"
            label="Riepilogo"
            readonly
            dense
          ></v-textarea>
          <v-flex d-flex>
            <v-text-field
              v-model="request.startDate"
              label="Data Inizio"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              v-model="request.startTime"
              label="Tempo Avviamento"
              outlined
              readonly
              v-if="request.startTime != ''"
              dense
            ></v-text-field>
          </v-flex>
          <v-flex d-flex>
            <v-text-field
              v-model="request.endTime"
              label="Scadenza"
              outlined
              readonly
              v-if="request.endTime != ''"
              :color="getDeadlineColor(daysFromNow(request.endTime))"
              dense
            ></v-text-field>
            <v-text-field
              v-model="request.closeDate"
              label="Data Chiusura"
              outlined
              readonly
              v-if="request.closeDate != ''"
              dense
            ></v-text-field>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="closeRequest(request)"
            >Chiudi richiesta</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newRequestDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="newRequestDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Aggiungi Richiesta</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="save" :disabled="saveDisabled">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <br />
          <br />
          <br />
          <br />
          <v-autocomplete
            label="Cliente"
            hide-no-data
            hide-selected
            :items="listaClienti"
            item-text="name"
            item-value="c_bpartner_id"
            :search-input.sync="ricercaCliente"
            v-model="c_bpartner_id"
            outlined
            dense
          ></v-autocomplete>
          <v-select
            v-model="r_requesttype_id"
            :items="requestTypes"
            item-text="name"
            item-value="r_requesttype_id"
            outlined
            label="Tipo Richiesta"
            dense
          ></v-select>
          <v-select
            v-model="confidentialType"
            :items="confidentialTypes"
            item-text="name"
            item-value="value"
            outlined
            label="Visibilità"
            dense
          ></v-select>
          <v-select
            v-model="salesrep_id"
            :items="salesReps"
            item-text="name"
            item-value="ad_user_id"
            outlined
            label="Responsabile"
            dense
          ></v-select>
          <v-textarea v-model="summary" outlined label="Riepilogo"></v-textarea>
          <v-flex d-flex>
            <DatePicker v-model="startDate" label="Data Inizio" outlined dense/>
            <DatePicker
              v-model="endTime"
              label="Scandenza"
              outlined
              clearable
              dense
            />
          </v-flex>
          <v-flex d-flex>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save" :disabled="saveDisabled">
              Salva
            </v-btn>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import DatePicker from "@/components/DataPicker";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  mixins: [mixin],
  components: { DatePicker, ConfirmDialog },
  data() {
    return {
      request: {},
      requestDialog: false,
      newRequestDialog: false,
      ricercaCliente: "",
      listaClienti: [],
      c_bpartner_id: 0,
      summary: "",
      salesReps: [],
      salesrep_id: 0,
      startDate: new Date().toISOString().substring(0, 10),
      endTime: "",
      requestTypes: [],
      r_requesttype_id: 0,
      confidentialType: "C",
      confidentialTypes: [
        { name: "Confidenziale", value: "C" },
        { name: "Pubblico", value: "A" },
        { name: "Interno", value: "I" },
        { name: "Privato", value: "P" },
      ],
    };
  },
  methods: {
    getRequests() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getRequests";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        let requests = response.data.requests;
        requests.sort((a, b) => {
          let datea = new Date(a.startDate);
          let dateb = new Date(b.startDate);
          return dateb.getTime() > datea.getTime() ? 1 : -1;
        });
        this.requests = requests;
      });
    },
    getSalesReps() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getSalesReps";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.salesReps = response.data.salesReps;
      });
    },
    getRequestTypes() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getRequestTypes";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.requestTypes = response.data.requestTypes;
      });
    },
    openRequestDialog(request) {
      this.request = request;
      this.requestDialog = true;
    },
    openNewRequestDialog() {
      this.startDate = new Date().toISOString().substring(0, 10);
      this.endTime = "";
      this.summary = "";
      this.ricercaCliente = "";
      this.c_bpartner_id = 0;
      this.listaClienti = [];
      this.salesrep_id = 0;
      this.r_requesttype_id = 0;
      this.requestType = "C";
      this.newRequestDialog = true;
    },
    closeRequest(request) {
      this.$refs.confirm
        .open("Chiudi", "Vuoi chiudere la richiesta?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.requestDialog = false;
            let parameters = {};
            parameters.data = { r_request_id: request.r_request_id };
            parameters.idempiereRestPath = "effecitech/closeRequest";
            this.$store
              .dispatch("callIdempiereRest", parameters)
              .then((response) => {
                if (response.data.result == "OK") {
                  let requests = this.requests;
                  this.requests = requests.filter((item) => {
                    return item.r_request_id != request.r_request_id;
                  });
                }
              });
          }
        });
    },
    daysFromNow(datestr) {
      let date = new Date(datestr);
      let today = new Date();
      let difftime = date.getTime() - today.getTime();
      return Math.round(difftime / (1000 * 3600 * 24));
    },
    getDeadlineColor(days) {
      if (days <= 7 && days > 0) {
        return "warning";
      } else if (days <= 0) {
        return "error";
      } else {
        return "success";
      }
    },
    save() {
      this.showLoadingDialog(true, "Creazione Richiesta...");
      this.newRequestDialog = false;
      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_bpartner_id,
        r_requesttype_id: this.r_requesttype_id,
        salesrep_id: this.salesrep_id,
        summary: this.summary,
        startDate: this.startDate,
        endTime: this.endTime,
        confidentialType: this.confidentialType,
      };
      parameters.idempiereRestPath = "effecitech/addRequest";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          let requests = this.requests;
          requests.push({
            r_request_id: response.data.r_request_id,
            r_requesttype_id: this.r_requesttype_id,
            salesrep_id: this.salesrep_id,
            c_bpartner_id: this.c_bpartner_id,
            bpname: this.ricercaCliente,
            summary: this.summary,
            startDate: this.startDate,
            startTime: "",
            endTime: this.endTime,
            closeDate: "",
            result: "",
          });
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
  },
  mounted() {
    this.getRequests();
    this.getSalesReps();
    this.getRequestTypes();
  },
  computed: {
    requests: {
      cache:false,
      get() {
        return this.$store.state.requests;
      },
      set(value) {
        this.$store.commit("setRequests", value);
      },
    },
    requestGrouped:{
      cache:false,
      get(){
        let requestGrouped = {}
        for(let request of this.requests){
          if(!requestGrouped[request.bpname]){
            requestGrouped[request.bpname]=[];
          }
          requestGrouped[request.bpname].push(request)
        }
        return requestGrouped;
      }
    },
    saveDisabled: function () {
      if (this.c_bpartner_id == null || this.c_bpartner_id <= 0) return true;
      if (this.salesrep_id == null || this.salesrep_id <= 0) return true;
      if (this.r_requesttype_id == null || this.r_requesttype_id <= 0)
        return true;
      if (this.summary == null || this.summary == "") return true;
      if (this.startDate == null || this.startDate == "") return true;
      return false;
    },
  },
  watch: {
    ricercaCliente: function () {
      if (
        this.ricercaCliente == null ||
        this.ricercaCliente == "" ||
        this.ricercaCliente.length < 3
      )
        return;
      let parameters = {};
      parameters.data = { name: this.ricercaCliente };
      parameters.idempiereRestPath = "effecitech/cercaCliente";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.listaClienti = response.data.listaClienti;
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.requestDialog) {
      this.requestDialog = false;
      next(false);
    } else if (this.newRequestDialog) {
      this.newRequestDialog = false;
      next(false);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.requestDialog) {
      this.requestDialog = false;
      next(false);
    } else if (this.newRequestDialog) {
      this.newRequestDialog = false;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style>
</style>