var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('data-picker',{attrs:{"outlined":"","dense":"","label":"Data Inizio","type":"month"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('data-picker',{attrs:{"outlined":"","dense":"","label":"Data Fine","type":"month"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('apexchart',{attrs:{"height":"500px","type":"bar","options":_vm.chartOptions,"series":_vm.series}}),_c('br'),_c('v-flex',{attrs:{"d-flex":""}},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v("Acquisti")]),_c('v-tab',[_vm._v("Vendite")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.openAddDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Aggiungi")],1)],1),_c('br'),(_vm.tabs == 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paySchedulesPurchase,"dense":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('data-picker',{attrs:{"type":"month","dense":"","hide-details":""},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deletePaySchedulesPurchase(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paySchedulesSales,"dense":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('data-picker',{attrs:{"type":"month","dense":"","hide-details":""},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deletePaySchedulesSales(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}),_c('br'),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Aggiungi")]),_c('v-card-text',[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Tipo","items":_vm.payScheduleTypes,"item-text":"text","item-value":"value"},model:{value:(_vm.paySchedule.isSOTrx),callback:function ($$v) {_vm.$set(_vm.paySchedule, "isSOTrx", $$v)},expression:"paySchedule.isSOTrx"}}),_c('data-picker',{attrs:{"outlined":"","dense":"","label":"Data","type":"month"},model:{value:(_vm.paySchedule.date),callback:function ($$v) {_vm.$set(_vm.paySchedule, "date", $$v)},expression:"paySchedule.date"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Importo","type":"number"},model:{value:(_vm.paySchedule.amt),callback:function ($$v) {_vm.$set(_vm.paySchedule, "amt", $$v)},expression:"paySchedule.amt"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.addDialog = false}}},[_vm._v("Chiudi")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":_vm.addPaySchedule}},[_vm._v("Aggiungi")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }