<template>
  <v-container>
    <v-flex d-flex>
      <v-autocomplete
        label="Cliente"
        hide-no-data
        hide-selected
        :items="customers"
        item-text="name"
        item-value="c_bpartner_id"
        v-model="c_bpartner_id"
        outlined
        @change="getBPartnerAssets"
        dense
        :disabled="edit"
      ></v-autocomplete>
      <v-btn :disabled="edit" icon @click="qrcodeDialog = true"
        ><v-icon large>mdi-qrcode</v-icon></v-btn
      >
    </v-flex>
    <v-select
      label="Dispositivo"
      v-model="asset"
      :items="listaAsset"
      item-value="a_asset_id"
      item-text="displayvalue"
      return-object
      no-data-text="Nessun dispositivo"
      outlined
      :disabled="c_bpartner_id <= 0 || edit"
      @change="getAsset(asset.a_asset_id, null)"
      dense
    >
    </v-select>
    <span v-if="asset != null">
      <v-tabs dense v-model="tab">
        <v-tab>Dispositivo</v-tab>
        <v-tab :disabled="edit">Interventi</v-tab>
      </v-tabs>
      <br />
      <span v-if="tab == 0">
        <v-form v-model="form" ref="form">
          <v-expansion-panels multiple flat v-model="panels" dense>
            <v-expansion-panel dense>
              <v-expansion-panel-header
                >Dispositivo codice: {{ asset.value }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="td-label"><h4>Cliente:</h4></td>
                      <td>
                        <v-autocomplete
                          v-if="edit"
                          hide-no-data
                          hide-selected
                          :items="customers"
                          item-text="name"
                          item-value="c_bpartner_id"
                          v-model="asset.c_bpartner_id"
                          dense
                          class="minified"
                        ></v-autocomplete>
                        <span v-else>{{
                          getCustomerName(asset.c_bpartner_id)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-label"><h4>Tipo:</h4></td>
                      <td>
                        <v-select
                          v-model="asset.a_asset_group_id"
                          :items="assetGroups"
                          item-text="name"
                          item-value="a_asset_group_id"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-select>
                        <span v-else>{{
                          getAssetGroupName(asset.a_asset_group_id)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Nome:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.name"
                          dense
                          class="minified"
                          v-if="edit"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <span v-else>{{ asset.name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Descrizione:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.description"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.description }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Prodotto:</h4></td>
                      <td>
                        <v-autocomplete
                          v-model="asset.m_product_id"
                          hide-no-data
                          hide-selected
                          :items="listaProdotti"
                          item-text="searchstr"
                          item-value="m_product_id"
                          :search-input.sync="ricercaProdotto"
                          class="minified"
                          v-if="edit"
                        ></v-autocomplete>
                        <span v-else>{{ asset.pname }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Note:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.note"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.note }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Anno costruzione:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.manufacturedYear"
                          type="number"
                          dense
                          class="minified"
                          v-if="edit"
                          :rules="[rules.validYear]"
                        ></v-text-field>
                        <span v-else>{{ asset.manufacturedYear }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Link documentazione:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.doclink"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.doclink }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Indirizzo ip:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.ip_address"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.ip_address }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Utenti e password:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.user_and_password"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.user_and_password }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Data messa in servizio:</h4></td>
                      <td>
                        <DatePicker
                          v-model="asset.assetServiceDate"
                          class="minified"
                          v-if="edit"
                        />
                        <span v-else>{{
                          dateToItalianFormat(asset.assetServiceDate)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Dati tecnici</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="td-label"><h4>Serial number:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.serno"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.serno }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Modello:</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.assetModel"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.assetModel }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Versione S.O. :</h4></td>
                      <td>
                        <v-text-field
                          v-model="asset.osVersion"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-text-field>
                        <span v-else>{{ asset.osVersion }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Data garanzia :</h4></td>
                      <td>
                        <DatePicker
                          v-model="asset.guaranteeDate"
                          class="minified"
                          v-if="edit"
                          clearable
                        />
                        <span v-else>{{
                          dateToItalianFormat(asset.guaranteeDate)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Altro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="td-label"><h4>Dismesso:</h4></td>
                      <td>
                        <v-checkbox
                          v-model="asset.disposed"
                          dense
                          class="minified"
                          v-if="edit"
                        ></v-checkbox>
                        <span v-else>{{ asset.disposed ? "SI" : "NO" }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Data dismissione:</h4></td>
                      <td>
                        <DatePicker
                          v-model="asset.assetDisposalDate"
                          class="minified"
                          v-if="edit && asset.disposed"
                          clearable
                        />
                        <span v-else>{{
                          dateToItalianFormat(asset.assetDisposalDate)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><h4>Posizione</h4></td>
                      <td>
                        <v-btn text color="primary" v-if="edit" @click="setCurrentAssetPosition"
                          ><v-icon>mdi-crosshairs-gps</v-icon>Usa attuale</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="openMap"
                          v-else-if="
                            asset.latitude != null && asset.longitude != null
                          "
                          ><v-icon>mdi-map-marker</v-icon>Mappa</v-btn
                        >
                        <span v-else><v-icon color="error">mdi-close</v-icon>Non Impostata</span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
        <br />
        <v-flex d-flex v-if="!edit">
          <v-spacer></v-spacer>
          <v-btn text @click="modifyAsset"
            ><v-icon>mdi-pencil</v-icon>Modifica</v-btn
          >
        </v-flex>
        <v-flex d-flex v-if="edit">
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAssetChanges" color="error"
            ><v-icon>mdi-cancel</v-icon>Ripristina</v-btn
          >
          <v-btn
            text
            @click="saveAssetChanges"
            :disabled="!form"
            color="success"
            ><v-icon>mdi-check</v-icon>Salva</v-btn
          >
        </v-flex>
      </span>
      <span v-else-if="tab == 1">
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Data Esecuzione</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="maintenance in asset.maintenances"
              :key="maintenance.fct_asset_maintenance_id"
              @click="openMaintenanceDialog(maintenance)"
            >
              <td>
                {{ getMaintenanceTypeName(maintenance) }}
              </td>
              <td>{{ dateToItalianFormat(maintenance.executionDate) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-flex d-flex>
          <v-spacer></v-spacer>
          <v-btn text @click="openMaintenanceDialog(null)"
            ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
          >
        </v-flex>
      </span>
    </span>
    <br />
    <br />
    <!--Dialog per la scansione del qrcode-->
    <v-dialog
      v-model="qrcodeDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      max-height="100%"
    >
      <v-card>
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="qrcodeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Scansiona il QR-Code</h4>
        </v-app-bar>
        <v-card-text>
          <br />
          <br />
          <br />
          <v-flex>
            <h4>Inserisci il codice manualmente</h4>
            <v-text-field
              v-model="valueAssetRicerca"
              dense
              class="minified"
              v-on:keyup.enter="updateValueAssetRicerca"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
          </v-flex>
        </v-card-text>
      </v-card>

      <!--Dialog per l'intervento-->
    </v-dialog>
    <v-dialog
      v-if="maintenance != null"
      v-model="maintenanceDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-app-bar color="effecitech" fixed>
          <v-btn icon @click="maintenanceDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h4>Intervento</h4>
          <v-spacer></v-spacer>
          <v-btn
            @click="addMaintenance"
            icon
            v-if="newMaintenance"
            :disabled="addMaintenanceDisabled"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
          <v-btn
            @click="saveMaintenance"
            icon
            v-else
            :disabled="addMaintenanceDisabled"
            ><v-icon>mdi-content-save-outline</v-icon></v-btn
          >
        </v-app-bar>
        <br />
        <br />
        <br />
        <v-card-text>
          <v-select
            label="Tipo"
            :items="asset.maintenancePlannings"
            item-text="maintenanceDescription"
            item-value="fct_maintenance_planning_id"
            v-model="maintenance.fct_maintenance_planning_id"
            outlined
            dense
            @change="calculateNextMaintenanceDate"
          ></v-select>
          <DatePicker
            v-model="maintenance.executionDate"
            label="Data Esecuzione"
            outlined
            dense
            @change="calculateNextMaintenanceDate"
          />
          <DatePicker
            v-model="maintenance.nextMaintenanceDate"
            label="Data Prossimo Intervento"
            outlined
            dense
          />
          <v-textarea
            label="Report"
            v-model="maintenance.maintenanceReport"
            outlined
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Snackbar errori scansione qrcode-->
    <v-snackbar v-model="snackbar" color="error" :timeout="3000"
      ><v-icon>mdi-alert-octagon-outline</v-icon
      ><b>{{ snackbarText }}</b></v-snackbar
    >
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import { QrcodeStream } from "vue-qrcode-reader";
import DatePicker from "@/components/DataPicker";

export default {
  mixins: [mixin],
  components: {
    QrcodeStream,
    DatePicker,
  },
  data() {
    return {
      valueAssetRicerca: "",
      c_bpartner_id: 0,
      ricercaProdotto: "",
      listaAsset: [],
      listaProdotti: [],
      asset: null,
      assetTemp: null,
      panels: [0],
      qrcodeDialog: false,
      tab: 0,
      maintenanceDialog: false,
      maintenance: null,
      newMaintenance: false,
      snackbar: false,
      snackbarText: "",
      camera: "auto",
      edit: false,
      customers: [],
      assetGroups: [],
      form: false,
      rules: {
        required: (value) => (value ? true : "Campo richiesto!"),
        validYear: (value) => value >= 1990,
        select: (value) => value != 0,
      },
    };
  },
  methods: {
    updateValueAssetRicerca() {
      console.log("updateValueAssetRicerca");
      this.qrcodeDialog = false;
      this.getAsset(null, this.valueAssetRicerca);
      this.valueAssetRicerca = null;
    },
    getBPartnerAssets() {
      this.showLoadingDialog(true, "Ricerca dispositivi...");
      let parameters = {};
      parameters.data = { c_bpartner_id: this.c_bpartner_id };
      parameters.idempiereRestPath = "effecitech/getBPartnerAssets";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.listaAsset = response.data.assets;
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    getAsset(a_asset_id, value) {
      this.showLoadingDialog(true, "Caricamento asset...");
      let parameters = {};
      if (a_asset_id != null) {
        parameters.data = { a_asset_id: a_asset_id };
      } else if (value != null) {
        parameters.data = { value: value };
      }
      parameters.idempiereRestPath = "effecitech/getAsset";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        if (response.data.result == "OK") {
          this.asset = response.data.asset;
          this.showLoadingDialog(false);
          if (this.asset == null) {
            this.showErrorDialog("Nessun dispositivo trovato!");
          }
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    async onDecode(resultRaw) {
      try {
        let result = JSON.parse(resultRaw);
        if (result.a_asset_id != null) {
          this.qrcodeDialog = false;
          this.getAsset(result.a_asset_id, null);
        } else {
          this.snackbarText = "Errore nella decodifica del QR-Code!";
          this.snackbar = true;
        }
      } catch (error) {
        console.log(error);
        this.snackbarText = "Errore nella decodifica del QR-Code!";
        this.snackbar = true;
      } finally {
        this.pause();
        await this.timeout(500);
        this.unpause();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.snackbarText =
            "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.snackbarText = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.snackbarText =
            "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.snackbarText = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.snackbarText = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.snackbarText =
            "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.snackbarText =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.snackbarText = `ERROR: Camera error (${error.name})`;
        }
        this.qrcodeDialog = false;
        this.snackbar = true;
      } finally {
        this.camera === "auto";
      }
    },
    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    getMaintenanceTypeName(maintenance) {
      if (
        maintenance.fct_maintenance_planning_id == null ||
        maintenance.fct_maintenance_planning_id <= 0
      )
        return maintenance.maintenanceReport;
      let item = this.asset.maintenancePlannings.find((element) => {
        return (
          element.fct_maintenance_planning_id ==
          maintenance.fct_maintenance_planning_id
        );
      });
      return item.maintenanceDescription;
    },
    openMaintenanceDialog(maintenance) {
      if (maintenance != null) {
        this.maintenance = maintenance;
        this.newMaintenance = false;
      } else {
        this.maintenance = {
          executionDate: new Date().toISOString().substring(0, 10),
        };
        this.newMaintenance = true;
      }
      this.maintenanceDialog = true;
    },
    addMaintenance() {
      this.showLoadingDialog(true, "Creazione Intervento...");
      this.maintenanceDialog = false;
      let parameters = {};
      parameters.data = {
        fct_maintenance_planning_id:
          this.maintenance.fct_maintenance_planning_id,
        executionDate: this.maintenance.executionDate,
        maintenanceReport: this.maintenance.maintenanceReport,
        nextMaintenanceDate: this.maintenance.nextMaintenanceDate,
        a_asset_id: this.asset.a_asset_id,
      };
      parameters.idempiereRestPath = "effecitech/addAssetMaintenance";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.asset.maintenances.push({
            ...parameters.data,
            fct_asset_maintenance_id: response.data.fct_asset_maintenance_id,
          });
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    saveMaintenance() {
      this.showLoadingDialog(true, "Salvataggio Intervento...");
      this.maintenanceDialog = false;
      let parameters = {};
      parameters.data = {
        fct_maintenance_planning_id:
          this.maintenance.fct_maintenance_planning_id,
        executionDate: this.maintenance.executionDate,
        maintenanceReport: this.maintenance.maintenanceReport,
        nextMaintenanceDate: this.maintenance.nextMaintenanceDate,
        fct_asset_maintenance_id: this.maintenance.fct_asset_maintenance_id,
        a_asset_id: this.asset.a_asset_id,
      };
      parameters.idempiereRestPath = "effecitech/addAssetMaintenance";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          let element = this.asset.maintenances.find((item) => {
            return (item.fct_asset_maintenance_id =
              response.data.fct_asset_maintenance_id);
          });
          element.fct_maintenance_planning_id =
            this.maintenance.fct_maintenance_planning_id;
          element.executionDate = this.maintenance.executionDate;
          element.maintenanceReport = this.maintenance.maintenanceReport;
          element.nextMaintenanceDate = this.maintenance.nextMaintenanceDate;
          element.fct_asset_maintenance_id =
            response.data.fct_asset_maintenance_id;
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    calculateNextMaintenanceDate() {
      if (this.maintenance.fct_maintenance_planning_id == null) return;
      if (this.maintenance.fct_maintenance_planning_id <= 0) return;
      if (this.maintenance.executionDate == null) return;
      if (this.maintenance.executionDate == "") return;
      let planning = this.asset.maintenancePlannings.find((item) => {
        return (
          item.fct_maintenance_planning_id ==
          this.maintenance.fct_maintenance_planning_id
        );
      });
      let uom = planning.uomCode.trim();
      let frequency = planning.frequency;
      let nextMaintenanceDate = new Date(this.maintenance.executionDate);
      switch (uom) {
        case "YR":
          nextMaintenanceDate.setFullYear(
            nextMaintenanceDate.getFullYear() + frequency
          );
          break;
        case "MO":
          nextMaintenanceDate.setMonth(
            nextMaintenanceDate.getMonth() + frequency
          );
          break;
        case "DA":
          nextMaintenanceDate.setDate(
            nextMaintenanceDate.getDate() + frequency
          );
          break;
      }
      this.maintenance.nextMaintenanceDate = nextMaintenanceDate
        .toISOString()
        .substring(0, 10);
    },
    async getCustomers() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAllCustomers";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.customers = response.data.customers;
        });
    },
    async getAssetGroups() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getAssetGroups";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.assetGroups = response.data.assetGroups;
        });
    },
    getCustomerName(c_bpartner_id) {
      if (c_bpartner_id == 0) return "";
      let customer = this.customers.find((item) => {
        return item.c_bpartner_id == c_bpartner_id;
      });
      return customer.name;
    },
    getAssetGroupName(a_asset_group_id) {
      let customer = this.assetGroups.find((item) => {
        return item.a_asset_group_id == a_asset_group_id;
      });
      return customer.name;
    },
    modifyAsset() {
      this.assetTemp = { ...this.asset };
      this.ricercaProdotto = this.asset.pname;
      this.panels = [0, 1, 2];
      this.edit = true;
      this.$refs.form.validate();
    },
    cancelAssetChanges() {
      if (!this.edit) return;
      this.edit = false;
      this.asset = this.assetTemp;
      this.assetTemp = null;
    },
    saveAssetChanges() {
      if (!this.edit) return;
      if (!this.$refs.form.validate()) return;
      this.showLoadingDialog(true, "Salvataggio...");
      let parameters = {};
      parameters.data = { ...this.asset };
      delete parameters.data.maintenancePlannings;
      delete parameters.data.maintenances;
      parameters.idempiereRestPath = "effecitech/updateAsset";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.assetTemp = null;
          this.edit = false;
          let prodotto = this.listaProdotti.find((item) => {
            return item.m_product_id == this.asset.m_product_id;
          });
          this.asset.pname = prodotto.name;
          this.asset.value = prodotto.value;
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    openMap() {
      let url = "http://maps.google.com/maps?q=";
      url += this.asset.latitude;
      url += ",";
      url += this.asset.longitude;
      window.open(url);
    },
    setCurrentAssetPosition() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.asset.latitude = pos.coords.latitude;
          this.asset.longitude = pos.coords.longitude;
          console.log("ciao");
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
  },
  async mounted() {
    this.showLoadingDialog(true, "Caricamento Clienti...");
    await this.getCustomers();
    await this.getAssetGroups();
    this.showLoadingDialog(false);
  },
  watch: {
    ricercaProdotto: function () {
      if (
        this.ricercaProdotto == null ||
        this.ricercaProdotto == "" ||
        this.ricercaProdotto.length < 3
      )
        return;
      let parameters = {};
      parameters.data = { value: this.ricercaProdotto };
      parameters.idempiereRestPath = "effecitech/cercaProdotto";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.listaProdotti = response.data.listaProdotti;
        }
      });
    },
    tab: function (newValue, oldValue) {
      if (oldValue == 0 && this.edit) {
        this.cancelAssetChanges();
      }
    },
  },
  computed: {
    addMaintenanceDisabled: function () {
      if (
        this.maintenance.executionDate == null ||
        this.maintenance.executionDate == ""
      )
        return true;
      if (
        this.maintenance.maintenanceReport == null ||
        this.maintenance.maintenanceReport == ""
      )
        return true;
      return false;
    },
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel-header {
  padding: 5px !important;
  min-height: 1px !important;
}
.minified .v-text-field__details {
  display: none;
}

.minified .v-input__control .v-input__slot .v-text-field__slot input {
  padding: 0 !important;
  font-size: 0.875rem;
}

.minified .v-text-field {
  padding: 0 !important;
}

.minified .v-select__slot input {
  padding: 0 !important;
  font-size: 0.875rem;
}

.minified .v-select__selections {
  font-size: 0.875rem;
}

.td-label {
  min-width: 30%;
  max-width: 30%;
  width: 30%;
}
</style>